import React from "react";
import Above from "../../components/campaigns/cashflow/Above";
import AboveSecond from "../../components/campaigns/cashflow/AboveSecond";
import Criteria from "../../components/campaigns/cashflow/Criteria";
import HowGetStarted from "../../components/campaigns/cashflow/HowGetStarted";
import GetInTouch from "../../components/global/forms/GetInTouch";

const LandingPreview = ({ entry, fieldsMetaData }) => {
  const data = entry?.getIn(["data"])?.toJS();
  if (data) {
    return (
      <>
        <Above {...data?.banner} isPreview />
        {data?.facts && <AboveSecond {...data?.facts} />}
        {data?.getStarted && <HowGetStarted {...data?.getStarted} />}
        {data?.criteria && <Criteria {...data?.criteria} />}
        {data?.getInTouch && <GetInTouch {...data?.getInTouch} isPreview/>}
      </>
    );
  } else {
    return <div>Loading...</div>;
  }
};

export default LandingPreview;
